import React from "react";

const UserRecord = ({ handleRecordClick, data, index }) => {
  const { name, email, language, isAdmin } = data;
  return (
    <tr className={`has-text-centered`}>
      <td>{index + 1}</td>
      <td>
        {name}{" "}
        {isAdmin && <em className="has-text-danger is-size-6">[Admin]</em>}
      </td>
      <td>{email}</td>
      <td>
        {language.auditorFor.join() || (
          <em className="has-text-grey-light">none</em>
        )}
      </td>
      <td>
        {language.translatorFor.join() || (
          <em className="has-text-grey-light">none</em>
        )}
      </td>
      <td>
        {language.reviewerFor.join() || (
          <em className="has-text-grey-light">none</em>
        )}
      </td>
      <td>
        <button onClick={() => handleRecordClick(data)} className="button">
          Edit
        </button>
      </td>
    </tr>
  );
};

export default UserRecord;
