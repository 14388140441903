import React from "react";

const GeneralRecord = ({ handleRecordClick, data, index }) => {
  return (
    <tr className={`has-text-centered`}>
      {Object.values(data).map((item, idx) => (
        <td key={"generalRecord-" + idx}>{item}</td>
      ))}
    </tr>
  );
};

export default GeneralRecord;
