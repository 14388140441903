import React from 'react';
import { useLocation } from 'react-router-dom';

const TranslationRecord = ({ handleRecordClick, data, index }) => {
	const {
		id,
		translation_value,
		translation_value_current,
		translation_value_review,
		translation_status,
		language,
	} = data;

	const location = useLocation();
	return (
		<tr
		
			className={`has-text-centered ${translation_status === 'reviewed' ? '' : ''}`}
		>
			<td>{index + 1}</td>
			<td>{id}</td>
			<td>{translation_value}</td>
			<td>{language.name_native}</td>
			<td className={`${translation_status === 'reviewed' ? 'has-text-success' : ''}`}>
				{translation_status || 'untranslated'}
			</td>
			{location.pathname === '/reviews' && (
				<td>{translation_value_review || <em className="has-text-grey-light">Not reviewed yet</em>}</td>
			)}
			<td>{translation_value_current || <em className="has-text-grey-light">Not translated yet</em>}</td>
			<td>
				<button className="button" 	onClick={() => handleRecordClick(data)}>Edit</button>
			</td>
		</tr>
	);
};

export default TranslationRecord;
