import React from "react";
import { Route } from "react-router-dom";
import HomePage from "../../pages/home.page";
import UserContext from "../../utils/user.context";

const PrivateRoute = ({ children, ...rest }) => {
  const user = React.useContext(UserContext);
  return (
    <Route
      {...rest}
      render={(props) => {
        return user.isAuth ? children : <HomePage />;
      }}
    />
  );
};

export default PrivateRoute;
