import React, { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import AdminPage from "../../pages/admin.page";
import TranslatePage from "../../pages/translate.page";
import ReviewPage from "../../pages/review.page";
import PrivateRoute from "../privateRoute/privateRoute.component";
import Header from "../header/header.component";

import UserContext from "../../utils/user.context";

const Routing = () => {
  const {
    isAuth,
    info: { isAdmin },
  } = useContext(UserContext);

  return (
    <div className="content">
      <div className="layout">
        {isAuth && <Header />}
        <Switch>
          <PrivateRoute exact path="/translate">
            <TranslatePage />
          </PrivateRoute>
          <PrivateRoute exact path="/reviews">
            <ReviewPage />
          </PrivateRoute>
          <PrivateRoute exact path="/admin">
            {isAdmin ? <AdminPage /> : <Redirect to="/translate" />}
          </PrivateRoute>

          <Route path="*">
            <Redirect to={"/translate"} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default Routing;
