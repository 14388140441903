import React, { useContext } from "react";

import ErrorBoundary from "../components/errorBoundary/errorBoundary.component";
import TranslationTable from "../components/translationTable/translationTable.component";

import UserContext from "../utils/user.context";


const ReviewPage = () => {
  const { info } = useContext(UserContext);

  return (
    <div className="page">
      <div className="page-container">
        <ErrorBoundary>
          <TranslationTable isReview data={info.reviewer}/>
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default ReviewPage;
