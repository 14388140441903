import React from 'react';

import LoadingGif from '../../assets/imgs/loading.gif';

const Loading = () => {
	return (
		<div className="page-container ">
			
				
					<img src={LoadingGif} alt="loading" style={{ width: 360, height: 'auto' }} />
				
				<h1 className="title has-text-grey">{'Loading...'}</h1>
			
		</div>
	);
};

export default Loading;
