import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <article className="message is-danger">
          <div className="message-header">
            <p>Something went wrong!</p>
          </div>
          <div className="message-body">
            <strong>Error Information: </strong> <br />
            <h1 className="subtitle is-4">{this.state.error}</h1>
            {this.state.errorInfo && this.state.errorInfo.componentStack}
          </div>
        </article>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
