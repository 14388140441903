import React, { useState } from "react";

const TableFilters = ({
  languageCode,
  languages = [],
  limit,
  search,
  handleQueryChange,
}) => {
  const [searchForm, setSearchForm] = useState(search);
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    handleQueryChange("search", searchForm);
  };

  return (
    <div className="filter-container">
      <form onSubmit={handleSearchSubmit} className="field mr-3 has-addons">
        <div className="control has-addons has-icons-left ">
          <input
            className="input"
            type="value"
            name="search"
            onChange={(e) => {
              setSearchForm(e.target.value);
              if (!e.target.value) {
                handleQueryChange(e.target.name, e.target.value);
              }
            }}
            placeholder="Search for word..."
            value={searchForm}
          />
          <span className="icon is-small is-left">
            <i className="fas fa-search"></i>
          </span>
        </div>
        <div className="control">
          <button type="submit" className="button is-dark">
            Search
          </button>
        </div>
      </form>
      <div className="field is-grouped">
        <div className="field is-grouped">
          <div className="control my-2">
            <label htmlFor="limitSelector" className="label">
              Items per page:
            </label>
          </div>
          <div className="control mr-6 has-icons-left">
            <div className="select">
              <select
                value={limit}
                name="itemsPerPage"
                onChange={(e) =>
                  handleQueryChange(e.target.name, e.target.value)
                }
                id="limitSelector"
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="75">75</option>
                <option value="100">100</option>
              </select>
              <div className="icon is-small is-left">
                <i className="fas fa-list-ul"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="field is-grouped">
          <div className="control my-2">
            <label htmlFor="languageSelector" className="label">
              Language:
            </label>
          </div>
          <div className="control   has-icons-left">
            <div className="select">
              <select
                value={languageCode}
                onChange={(e) =>
                  handleQueryChange(e.target.name, e.target.value)
                }
                name="languageCode"
              >
                {languages.length
                  ? languages.map((language) => (
                      <option
                        key={language.id + language.language.code}
                        value={language.language.code}
                      >
                        {language.language.name_native}
                      </option>
                    ))
                  : ""}
              </select>
              <div className="icon is-small is-left">
                <i className="fas fa-globe"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableFilters;
