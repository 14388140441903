import React, { useState } from "react";
import GoogleLogin from "react-google-login";
import Logo from "../components/logo/logo.component";
import UserContext from "../utils/user.context";

const HomePage = ({ handleLogin }) => {
  const { handleAuth } = React.useContext(UserContext);

  const [error, setError] = useState("");
  return (
    <div className="page login-form">
      <div className="page-container page-container--alt ">
        <div className="container is-fluid has-text-centered">
          {" "}
          <div className="box">
            <Logo />
            <GoogleLogin
              clientId={process.env.REACT_APP_CLIENT_ID}
              theme="dark"
              onSuccess={(response) => handleAuth(response.tokenId)}
              onFailure={(response) => setError("Login failed")}
              isSignedIn={true}
              cookiePolicy={"single_host_origin"}
            />
            {error && <p className="block has-text-danger">{error}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
