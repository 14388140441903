import React, { Fragment } from "react";
import { useContext } from "react";
import { NavLink } from "react-router-dom";

import Logo from "../logo/logo.component";
import UserContext from "../../utils/user.context";

const Sidebar = () => {
  const { info } = useContext(UserContext);
  return (
    <div className="sidebar">
      <Logo />
      <nav className="nav">
        <ul className="nav-list">
          <Fragment>
            <NavLink
              exact
              to="/translate"
              activeClassName="nav-list__link--active"
              className="nav-list__link"
            >
              <li className="nav-list__item">Translations</li>
            </NavLink>
            <NavLink
              to="/reviews"
              activeClassName="nav-list__link--active"
              className="nav-list__link"
            >
              <li className="nav-list__item">Reviews</li>
            </NavLink>
            {info.isAdmin && (
              <NavLink
                to="/admin"
                activeClassName="nav-list__link--active"
                className="nav-list__link"
              >
                <li className="nav-list__item">Admin</li>
              </NavLink>
            )}
          </Fragment>
        </ul>
      </nav>
      <span className="about has-text-grey-light">v2.0.0</span>
    </div>
  );
};

export default Sidebar;
