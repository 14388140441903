import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import ErrorBoundary from "./components/errorBoundary/errorBoundary.component";
import Sidebar from "./components/sidebar/sidebar.component";
import MobileView404 from "./components/404/MobileView404.component";
import Routing from "./components/routing/routing.component";

import UserContext from "./utils/user.context";
import { getCookie, setCookie } from "./utils/cookies.util";
import { fetchLogin } from "./utils/api.util";
import "./App.scss";

function App() {
  const [user, setUser] = useState({
    isAuth: false,
    info: {
      name: "",
      email: "",
      imageUrl: "",
      isAdmin: "",
      userLanguageRoles: "",
    },
  });

  useEffect(() => {
    const gtoken = getCookie("gtoken");
    if (gtoken) {
      handleLogin(gtoken);
    }
    return () => {};
  }, []);

  const handleLogin = async (token) => {
    let response = await fetchLogin(token);

    let result = await response;
    let data = await result.json();

    if (data.success) {
      setCookie("gtoken", token, 0.041667);
      let roles = assignLanguageRoles(data.data.user_language_roles);
      setUser({
        isAuth: data.success,
        info: {
          name: data.data.name,
          email: data.data.email,
          imageUrl: data.data.user_img,
          isAdmin: data.data.is_admin,
          auditor: roles.auditor,
          translator: roles.translator,
          reviewer: roles.reviewer,
        },
      });
      // console.log(user);
    }
  };

  const handleLogout = () => {
    setCookie("gtoken", "", 0);
    setUser({
      isAuth: false,
      info: {
        name: "",
        email: "",
        imageUrl: "",
        isAdmin: "",
        auditor: [],
        translator: [],
        reviewer: [],
      },
    });
  };

  const handleAuth = (token) => {
    if (user.isAuth) {
      handleLogout();
    } else {
      handleLogin(token);
    }
  };

  const assignLanguageRoles = (languages) => {
    let auditor = [],
      translator = [],
      reviewer = [];

    languages.forEach((language) => {
      switch (language.role) {
        case "translator":
          translator.push(language);
          break;
        case "reviewer":
          reviewer.push(language);
          break;
        default:
          auditor.push(language);
      }
    });

    return { auditor, translator, reviewer };
  };

  return (
    <Router>
      <ErrorBoundary>
        <UserContext.Provider
          value={{ isAuth: user.isAuth, info: user.info, handleAuth }}
        >
          <MobileView404 />
          <div className="app is-hidden-mobile">
            {user.isAuth && <Sidebar />}
            <Routing />
          </div>
        </UserContext.Provider>
      </ErrorBoundary>
    </Router>
  );
}

export default App;
