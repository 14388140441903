import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { putTranslation } from "../../../utils/api.util";

const ModalBodyTranslations = ({ translation, ...props }) => {
  const [formData, setFormData] = useState({
    text: translation.translation_value_current || "",
    errors: "",
  });
  const location = useLocation();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    let url = "/api/translate/translation";

    if (location.pathname === "/reviews") {
      url = url + "?review=true";
    }

    let data = await putTranslation(url, translation.id, formData.text);

    if (data.success) {
      props.modalToggle(data.data);
    }
  };

  useEffect(() => {
    setFormData({
      ...formData,
      text: translation.translation_value_current || "",
    });
  }, [translation]);

  return (
    translation && (
      <div className="content box">
        <label className="label">English Text</label>
        <p className="block">{translation.translation_value}</p>
        <form onSubmit={handleFormSubmit} className="conatainer is-fluid">
          <div className="field">
            <label className="label">
              Text translated to{" "}
              <em className="has-text-danger">
                {translation.language.name_native}
              </em>{" "}
            </label>
            <div className="control">
              <textarea
                autoFocus
                className="textarea is-primary"
                placeholder="Enter translation here"
                onChange={(e) =>
                  setFormData({ ...formData, text: e.target.value })
                }
                value={formData.text}
              ></textarea>
            </div>
          </div>

          <button className={`button is-info`} type={`submit`}>
            {location.pathname === "/reviews"
              ? "Review Translation"
              : "Submit Translation"}
          </button>
        </form>
      </div>
    )
  );
};

export default ModalBodyTranslations;
