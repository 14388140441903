import React, { Fragment, useState } from "react";
import Component404 from "../404/404.component";
import Modal from "../modal/modal.component";
import ModalBodyTranslations from "../modal/modalBody/modalBodyTranslations.component";
import ModalBodyUser from "../modal/modalBody/modalBodyUser.component";
import GeneralRecord from "./records/generalRecords";
import TranslationRecord from "./records/translationRecord.component";
import UserRecord from "./records/userRecord.component";

const Table = ({ admin, indexSeed, records, noData, ...props }) => {
  const [modalToggle, setModalToggle] = useState(false);
  const [modalData, setModalData] = useState({
    language: { name_native: "" },
    translation_value: "",
    user_language_roles: [{ language: { code: "" }, role: "" }],
  });

  const handleRecordClick = (data) => {
    setModalData(data);
    setModalToggle(!modalToggle);
  };

  const headings = props.headings
    ? props.headings
    : admin
    ? [
        "S.no",
        "User Name",
        "Email",
        "Auditor",
        "Translator",
        "Reviewer",
        "Action",
      ]
    : props.isReview
    ? [
        "S.no",
        "ID",
        "English Text",
        "Language",
        "Status",
        "Reviewed Translation",
        "Current Translation",
        "Action",
      ]
    : [
        "S.no",
        "ID",
        "English Text",
        "Language",
        "Status",
        "Current Translation",
        "Action",
      ];

  return (
    <Fragment>
      {modalToggle ? (
        <Modal
          isActive={modalToggle}
          modalToggle={() => setModalToggle(!modalToggle)}
          body={
            admin ? (
              <ModalBodyUser
                userData={modalData}
                modalToggle={() => setModalToggle(!modalToggle)}
              />
            ) : (
              modalData && (
                <ModalBodyTranslations
                  review={props.review}
                  translation={modalData}
                  modalToggle={(data) => {
                    props.updateData(data);
                    setModalToggle(!modalToggle);
                  }}
                />
              )
            )
          }
        />
      ) : (
        <></>
      )}
      {records && records.length ? (
        <div className="table-container">
          <table
            className={`table is-striped ${
              !props.headings && "is-hoverable"
            } is-fullwidth`}
          >
            <thead>
              <tr className="has-text-centered">
                {headings.map((heading, idx) => (
                  <th key={"heading" + idx}>{heading}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {records &&
                (admin
                  ? records.map((record, idx) => {
                      return (
                        <UserRecord
                          key={"table-user" + idx}
                          handleRecordClick={(data) => handleRecordClick(data)}
                          data={record}
                          index={idx}
                        />
                      );
                    })
                  : props.headings
                  ? records.map((record, idx) => {
                      return <GeneralRecord data={record} index={idx} />;
                    })
                  : records.map((record, idx) => {
                      return (
                        <TranslationRecord
                          key={"table-translation" + idx}
                          handleRecordClick={(data) => handleRecordClick(data)}
                          data={record}
                          index={idx + indexSeed}
                        />
                      );
                    }))}
            </tbody>
          </table>
        </div>
      ) : (
        <Component404
          title={noData ? noData.title : "No data found"}
          subtitle={noData ? noData.subtitle : ""}
        />
      )}
    </Fragment>
  );
};

export default Table;
