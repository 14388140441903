import React from "react";

import logoImg from "../../assets/imgs/translation.svg";

const Logo = () => {
  return (
    <span className="logo">
      <div className="logo__img">
        <img src={logoImg} alt="" />
      </div>
      <div className="logo__text">Translations App</div>
    </span>
  );
};

export default Logo;
