import React, { useState, useContext } from "react";

import ErrorBoundary from "../components/errorBoundary/errorBoundary.component";
import TranslationTable from "../components/translationTable/translationTable.component";

import UserContext from "../utils/user.context";


const TranslatePage = () => {
  const { info } = useContext(UserContext);
  const [isTemplate, setIsTemplate] = useState(false);

  return (
    <div className="page">

        <div className="tabs mb-0 is-boxed">
          <ul>
            <li
              className={!isTemplate ? "is-active" : ""}
              onClick={() => setIsTemplate(false)}
            >
              <a href="#">
                <span className="icon is-small">
                  <i className="fas fa-quote-left" aria-hidden="true"></i>
                </span>
                <span>Translations</span>
              </a>
            </li>
            <li
              className={isTemplate ? "is-active" : ""}
              onClick={() => setIsTemplate(true)}

            >
              <a href="#">
                <span className="icon is-small">
                  <i className="fas fa-align-justify" aria-hidden="true"></i>
                </span>
                <span>Templates</span>
              </a>
            </li>
          </ul>
        </div>

      <div className="page-container">
        {isTemplate ? (
          <div className="has-text-centered">
            <h1 className="title is-1">Coming Soon!</h1>
            <h3 className="subtitle has-text-grey is-5">Under Construction</h3>
          </div>
        ) : (
          <ErrorBoundary>
            <TranslationTable data={info.translator} />
          </ErrorBoundary>
        )}
      </div>

    </div>
  );
};

export default TranslatePage;
