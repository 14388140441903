import React, { useState, useEffect } from "react";
import Component404 from "../404/404.component";
import Loading from "../loading/loading.component";
import ErrorBoundary from "../errorBoundary/errorBoundary.component";
import TableFilters from "../filters/tableFilter.component";
import Pagination from "../pagination/pagination.component";
import Table from "../table/table.component";

import { fetchTranslations } from "../../utils/api.util";


const TranslationTable = ({ data, isReview }) => {
  const [query, setQuery] = useState({
    page: 1,
    itemsPerPage: 10,
    languageCode: data.length ? data[0].language.code : "en-us",
    search: "",
  });

  const [totalItems, setTotalItems] = useState(0);
  const [translations, setTranslations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {

    fetchData();

  }, [query]);

  const fetchData = async () => {
    setLoading(true);
    let reqQuery =
      "?status=" +
      (isReview ? "complete" : "pending") +
      "&code=" +
      query.languageCode +
      "&page=" +
      query.page +
      "&limit=" +
      Math.max(10, query.itemsPerPage) +
      "&search=" +
      query.search;


    const data = await fetchTranslations(reqQuery);
    setLoading(false);


    if (data.success) {
      setTranslations(data.translations);
      setTotalItems(data.total_items);
    } else {
      setError({
        title: "An error has occured!",
        subtitle:
          "Try refershing the page or contact an admin is the problem persists.",
      });
    }
    setLoading(false);
  };

  const updateData = (data) => {
    let arr = translations;
    arr = arr.map((translation) => {
      if (translation.id === data.id) {
        if (isReview) {
          return {
            ...translation,
            translation_value_review: data.translation_value,
            translation_status: data.translation_status,
          };
        } else {
          return {
            ...translation,
            translation_value_current: data.translation_value,
            translation_status: data.translation_status,
          };
        }
      } else return translation;
    });

    setTranslations(arr);
  };

  return loading ? (
    <Loading />
  ) : !data.length ? (
    <div className="page">
      <div className="page-container">
        <Component404
          title={"No languages for role found!"}
          subtitle="Please contact an admin to get access to this page."
        />
      </div>
    </div>
  ) : (
    <ErrorBoundary>
      <TableFilters
        languageCode={query.languageCode}
        languages={data}
        limit={query.itemsPerPage}
        search={query.search}
        handleQueryChange={(key, value) => {
          setQuery({ ...query, page: 1, [key]: value });
        }}
      />
      <Table
        records={translations}
        indexSeed={(query.page - 1) * query.itemsPerPage}
        updateData={(data) => updateData(data)}
        isReview={isReview}
        noData={
          error || {
            title: query.search
              ? `Didn't find any matching items!`
              : `Nothing to ${isReview ? "review" : "translate"}`,
            subtitle: query.search ? "" : "Try another language",
          }
        }
      />
      <Pagination
        currentPage={query.page}
        totalPages={Math.ceil(totalItems / query.itemsPerPage) || 1}
        handlePageChange={(page) => {
          setQuery({ ...query, page: page });
        }}
      />
    </ErrorBoundary>
  );
};

export default TranslationTable;
