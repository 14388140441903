import { secureFetch } from "./fetch.util";

// App
async function fetchLogin(token) {
  return await fetch(process.env.REACT_APP_BE_URL + "/api/auth/login/", {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }),
  });
}

// admin.page
async function fetchUsers() {
  return secureFetch({
    url: "/api/admin/users/",
  });
}

// translationTable.component
function fetchTranslations(reqQuery) {
  return secureFetch({
    url: "/api/translate/translations" + reqQuery,
  });
}

// modalBodyLanguage.component
function fetchLanguages() {
  return secureFetch({
    url: "/api/translate/languages",
  });
}

function postLanguage(userEmail, currentLanguage) {
  return secureFetch({
    url: "/api/user/language",
    method: "POST",
    extraHeaders: {
      "Content-Type": "application/json",
    },
    body: {
      userEmail: userEmail,
      language: currentLanguage,
    },
  });
}

// modalBodyTranslations.component
function putTranslation(url, translationId, translationValue) {
  return secureFetch({
    url: url,
    method: "PUT",
    extraHeaders: {
      "Content-Type": "application/json",
    },
    body: {
      translationId: translationId,
      translationValue: translationValue,
    },
  });
}

// modalBodyUser.component
function putRoles(userEmail, data) {
  return secureFetch({
    url: "/api/admin/roles",
    method: "PUT",
    extraHeaders: {
      "Content-Type": "application/json",
    },
    body: {
      userEmail: userEmail,
      data: data,
    },
  });
}

export {
  fetchLogin,
  fetchUsers,
  fetchTranslations,
  fetchLanguages,
  postLanguage,
  putTranslation,
  putRoles,
};
