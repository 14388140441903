import React, { Fragment, useContext } from "react";

import { GoogleLogout } from "react-google-login";
import UserContext from "../../utils/user.context";

const Header = () => {
  const user = useContext(UserContext);

  // console.log(user);
  return (
    <div className="header">
      <Fragment>
        <figure className="image is-32x32 my-0 mx-0">
          <img
            className="is-rounded"
            alt="user icon"
            src={user.info.imageUrl}
          />
        </figure>
        <div className="user-name is-size-5 mx-3 has-text-grey">
          {user.info.name}
          {user.info.isAdmin && (
            <span className="subtitle is-6 has-text-danger">&nbsp;[Admin]</span>
          )}
        </div>
      </Fragment>

      <div className="header__btns">
        <GoogleLogout
          clientId={process.env.REACT_APP_CLIENT_ID}
          theme="dark"
          isSignedIn={true}
          onLogoutSuccess={() => {
            user.handleAuth();
          }}
          onFailure={() => {
            user.handleAuth();
          }}
        />
      </div>
    </div>
  );
};

export default Header;
