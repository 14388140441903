import React, { useState, useEffect } from "react";

import { putRoles } from "../../../utils/api.util";
import ModalBodyLanguage from "./modalBodyLanguage.component";

const ModalBodyUser = ({ userData, ...props }) => {
  const [user, setUser] = useState(userData);
  const [formData, setFormData] = useState([]);
  const [page, setPage] = useState("main");
  // {["language"]: role}

  useEffect(() => {
    setPage("main");
    setUser(userData);
    let arr = {};
    userData.user_language_roles.forEach((ulr) => {
      arr = {
        ...arr,
        [ulr.language.code]: ulr.role,
      };
    });

    setFormData(arr);
    // setModalData(translation);
    // setFormData({ ...formData, text: translation.translation_value_current })
  }, [userData]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let jsonData = Object.keys(formData).map((item) => {
      return {
        languageCode: item,
        role: formData[item],
      };
    });

    let data = await putRoles(user.email, jsonData);

    if (data.success) {
      props.modalToggle();
      setPage("main");
      window.location.reload();
    }
  };

  return page === "main" ? (
    <div className="container box">
      <h1 className="title is-5 has-text-centered">Edit User Info</h1>
      <div className="columns is-centered">
        <div className="column has-text-centered">
          <button
            onClick={() => setPage("language")}
            className="button is-link is-outlined"
          >
            Add a language
          </button>
        </div>
        <div className="column has-text-centered">
          <button
            onClick={() => setPage("role")}
            className="button is-info is-outlined"
          >
            Change language role
          </button>
        </div>
      </div>
    </div>
  ) : page === "role" ? (
    <div className="container box">
      <button
        onClick={() => setPage("main")}
        className="button is-small is-oulined my-3 is-dark"
      >
        {" "}
        {"<<"}Back
      </button>
      <form className="box" onSubmit={handleSubmit}>
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                <th>Language</th>
                <th>Role</th>
              </tr>
            </thead>
            <tfoot>
              <tr>
                <th>Language</th>
                <th>Role</th>
              </tr>
            </tfoot>
            <tbody>
              {user.user_language_roles.map((ulr, idx) => {
                return (
                  <tr className="is-centered">
                    <td>{ulr.language.name_english}</td>
                    <td>
                      <div className="field">
                        <div className="control">
                          <div className="select">
                            <select
                              onChange={handleChange}
                              name={ulr.language.code}
                              value={formData[ulr.language.code]}
                            >
                              {["auditor", "translator", "reviewer"].map(
                                (item) => (
                                  <option value={item}>{item}</option>
                                )
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <button className="button is-info" type="submit">
          Change roles
        </button>
      </form>
    </div>
  ) : (
    <div className="container box">
      <button
        onClick={() => setPage("main")}
        className="button is-small is-oulined my-3 is-dark"
      >
        {" "}
        {"<<"}Back
      </button>
      <ModalBodyLanguage
        userLanguages={userData.user_language_roles.map(
          (ulr) => ulr.language.code
        )}
        user={userData}
        parentFunction={() => window.location.reload()}
      />
    </div>
  );
};

export default ModalBodyUser;
