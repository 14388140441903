import React from "react";

import Image404 from "../../assets/imgs/404.png";

const Component404 = ({ title, subtitle }) => {
  return (
    <div className="page-container page-container--alt">
      <div className="container is-fluid">
        <div className="columns is-centered">
          <div className="column has-text-centered">
            <h1 className="title">{title || "Not found!"}</h1>
            <h1 className="subtitle is-5 has-text-grey">{subtitle || ""}</h1>
            <figure className="image is-4by3">
              <img src={Image404} alt="404"/>
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Component404;
