import React, { Fragment, useState, useEffect } from "react";
import { fetchLanguages, postLanguage } from "../../../utils/api.util";

const ModalBodyLanguage = ({ user, parentFunction, userLanguages = ["en"] }) => {
  const [currentLanguage, setCurrentLanguage] = useState("");
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let data = await fetchLanguages();

      let langData = [];
      if (data.success) {
        langData = data.data.filter((lang) => {
          return userLanguages.indexOf(lang.code) === -1;
        });
      }
      setLanguages(langData);
      setCurrentLanguage(langData[0].code);
    };

    fetchData();
    return () => {};
  }, [userLanguages]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    let data = await postLanguage(user.email, currentLanguage);

    if (data.success) {
      parentFunction(data);
    }
    // Throw error here
  };

  return (
    <section className="section box">
      <div className="columns is-centered">
        <div className="column">
          <form className="has-text-centered">
            <h1 className="title is-4">Add a language</h1>

            {user && (
              <Fragment>
                <h3 className="subtitle my-3 has-text-grey-dark is-6">
                  {user.name} <br />{" "}
                  <em className="has-text-grey"> {user.email}</em>
                </h3>
              </Fragment>
            )}
            <div className="columns is-centered">
              <div className="column is-5">
                <div className="field has-addons ">
                  <div className="control has-icons-left">
                    <div className="select">
                      <select
                        onChange={(e) => setCurrentLanguage(e.target.value)}
                      >
                        {languages.length &&
                          languages.map((lang) => {
                            return (
                              <Fragment key={lang.code}>
                              <option value={lang.code}>
                                {lang.name_english}
                              </option>
                              </Fragment>
                            );
                          })}
                      </select>
                      <div className="icon is-small is-left">
                        <i className="fas fa-globe"></i>
                      </div>
                    </div>
                  </div>

                  <div className="control">
                    <button
                      type="submit"
                      className="button is-info"
                      onClick={handleFormSubmit}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ModalBodyLanguage;
