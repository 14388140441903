import { getCookie } from "./cookies.util";

export const secureFetch = async ({
  url,
  method,
  body,
  extraHeaders = {},
  extraInfo
}) => {
  let token = getCookie("gtoken");

  let raw = {
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      ...extraHeaders
    }),
  };

  if (body) {
    raw["body"] = JSON.stringify({ ...body });
  }

  if (method) {
    raw["method"] = method;
  }

  if (extraInfo) {
    raw = { ...raw, ...extraInfo };
  }
  try {

    let response = await fetch(process.env.REACT_APP_BE_URL + url, raw);
    let data = await response.json();
    
    return data;
  } catch (err) {
    console.log("err: ", err);
  }
};
