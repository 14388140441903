import React from "react";
import Logo from "../logo/logo.component";

const MobileView404 = () => {
  return (
    <div className="is-hidden-tablet mobile-view has-background-light">
      <Logo />
      <h1 className="title has-text-grey-dark has-text-centered my-2">
        Mobile view not supported!
      </h1>
    </div>
  );
};

export default MobileView404;
