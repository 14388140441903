import React, { useEffect, useState } from "react";

import { getCookie } from "../utils/cookies.util";
import { fetchUsers } from "../utils/api.util";

import Loading from "../components/loading/loading.component";
import ErrorBoundary from "../components/errorBoundary/errorBoundary.component";
import Table from "../components/table/table.component";

const AdminPage = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isTranslations, setIsTranslations] = useState(false);

  useEffect(() => {
    let fetchData = async () => {
      let data = await fetchUsers();

      if (data.success) {
        let userData = data.data.map((item) => {
          let auditorFor = [];
          let translatorFor = [];
          let reviewerFor = [];

          item.user_language_roles.forEach((ulr) => {
            ulr.role === "reviewer"
              ? reviewerFor.push(" " + ulr.language.name_english)
              : ulr.role === "translator"
              ? translatorFor.push(" " + ulr.language.name_english)
              : auditorFor.push(" " + ulr.language.name_english);
          });
          return {
            name: item.user.name,
            email: item.user.email,
            isAdmin: item.user.is_admin,
            language: {
              auditorFor: auditorFor,
              translatorFor: translatorFor,
              reviewerFor: reviewerFor,
            },
            user_language_roles: item.user_language_roles,
          };
        });

        setUsers(userData);
        setLoading(false);
      }
    };

    fetchData();

    return () => {};
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <div className="page">
      <div className="tabs mb-0 is-boxed">
        <ul>
          <li
            className={!isTranslations ? "is-active" : ""}
            onClick={() => setIsTranslations(false)}
          >
            <a>
              <span className="icon is-small">
                <i className="fas fa-user" aria-hidden="true"></i>
              </span>
              <span>Users</span>
            </a>
          </li>
        </ul>
      </div>
      <div>
        <a
          href={`${
            process.env.REACT_APP_BE_URL
          }/api/translate/download/${getCookie("gtoken")}`}
          download
          className="button is-dark mt-6 ml-6"
        >
          Export translations
        </a>
      </div>

      {
        <ErrorBoundary>
          <div className="page-container is-justify-content-flex-start mt-6">
            <Table admin="admin" records={users} />
          </div>
        </ErrorBoundary>
      }
    </div>
  );
};

export default AdminPage;
