import React from "react";

const Pagination = ({ currentPage, totalPages, handlePageChange }) => {
  const PageNumbers = [];

  for (let i = 2; i < totalPages; i++) {
    PageNumbers.push(
      <li key={"pagination" + i} onClick={() => handlePageChange(i)}>
        <a
          className={`pagination-link ${i === currentPage && "is-current"}`}
          aria-label={`Page {i}`}
          aria-current="page"
        >
          {i}
        </a>
      </li>
    );
  }

  return (
    totalPages >= 2 && (
      <div className="action-container">
        <nav className="pagination" role="navigation" aria-label="pagination">
          <a
            className="pagination-previous"
            title="This is the first page"
            disabled={currentPage === 1}
            onClick={() => {
              if (currentPage !== 1) handlePageChange(currentPage - 1);
            }}
          >
            Previous
          </a>
          <ul className="pagination-list" style={{ listStyle: "none" }}>
            <li></li>
            <li
              onClick={() => {
                handlePageChange(1);
              }}
            >
              <a
                className={`pagination-link ${
                  1 === currentPage && "is-current"
                }`}
                aria-label={`Page 1`}
                aria-current="page"
              >
                1
              </a>
            </li>

            {PageNumbers.map((item) => item)}

            <li onClick={() => handlePageChange(totalPages)}>
              <a
                className={`pagination-link ${
                  totalPages === currentPage && "is-current"
                }`}
                aria-label={`Page ${totalPages}`}
                aria-current="page"
              >
                {totalPages}
              </a>
            </li>
          </ul>

          <a
            onClick={() => {
              if (currentPage !== totalPages) handlePageChange(currentPage + 1);
            }}
            disabled={currentPage === totalPages}
            className="pagination-next"
          >
            Next page
          </a>
        </nav>
      </div>
    )
  );
};

export default Pagination;
