import React from "react";

const Modal = ({ isActive, modalToggle, body, props }) => {
  return (
    <div className={`modal &{props.className} ${isActive ? "is-active" : ""}`}>
      <div className="modal-background" onClick={modalToggle}></div>
      <div className="modal-content">{body}</div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={modalToggle}
      ></button>
    </div>
  );
};

export default Modal;
